<template>
    <div>
        <v-btn icon plain color="text" @click="likeProject()">
            <v-icon size="18" :color="isLiked ? 'error lighten-1' : ''">
                fa-solid fa-heart
            </v-icon>
            {{ counter }}
        </v-btn>
        <v-dialog v-model="dialogOpen" persistent max-width="300">
            <v-card>
                <v-card-title>
                    {{ $t("messages.likeButton.dialogTitle") }}
                </v-card-title>
                <v-card-text>
                    {{ $t("messages.likeButton.dialog") }}
                </v-card-text>
                <v-card-actions class="justify-end">
                    <v-btn text @click="dialogOpen = false">
                        {{ $t("messages.likeButton.cancel") }}
                    </v-btn>
                    <v-btn
                        depressed
                        color="primary lighten2"
                        @click="registerRedirect()"
                    >
                        {{ $t("messages.likeButton.login") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { withAsync } from "@/helpers/withAsync.js";
import { likeProject } from "@/api/projectsApi.js";
import { mapState, mapGetters } from "vuex";

export default {
    name: "LikeBtn",

    props: {
        project: {
            required: true,
            type: Object,
        },
        type: {
            required: true,
            type: String,
        },
    },

    data() {
        return {
            isLiked: null,
            counter: 0,
            dialogOpen: false,
        };
    },

    computed: {
        ...mapState({
            user_role: (state) => state.auth.user.role,
        }),
        ...mapGetters({
            isLoggedIn: "auth/isLoggedIn",
        }),
    },

    methods: {
        async likeProject() {
            if (!this.isLoggedIn) {
                this.dialogOpen = true;
                return;
            }

            let url = this.isLiked
                ? `${this.user_role}/${this.type}/unlike`
                : `${this.user_role}/${this.type}/like`;
            const payload = {
                [`${this.type}_id`]: this.project.id,
            };
            const { response, error } = await withAsync(
                likeProject,
                payload,
                url
            );

            if (error) {
                return;
            }

            this.isLiked = response.data.data.isLiked;
            this.counter = response.data.data.likes.length;
        },

        registerRedirect() {
            this.$router.push({ name: "Register" });
        },
    },

    watch: {
        project() {
            this.isLiked = this.project?.isLiked;
            this.counter = this.project?.likes?.length;
        },
    },

    created() {
        this.isLiked = this.project?.isLiked;
        this.counter = this.project?.likes?.length;
    },
};
</script>

<style lang="scss" scoped></style>
