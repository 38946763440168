<template>
    <v-card
        :max-width="cardWidth"
        :height="cardHeigth"
        :loading="false"
        outlined
    >
        <v-card-text
            class="d-flex justify-center pa-0 card-content"
            @click="redirectToInfoPage()"
        >
            <v-img
                v-if="project.image_url || thumbnail"
                :src="computedImageSource"
                class="white--text"
                contain
                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                :height="mediaHeight"
            >
                <div
                    class="fill-height d-flex flex-column justify-space-between"
                >
                    <img
                        :src="
                            require(`../../assets/categories/${project.category ||
                                'nutrition'}.svg`)
                        "
                        height="40"
                        class="align-self-end mr-2 mt-2"
                        style="object-fit: contain"
                    />
                    <v-card-title
                        class="card-title word__wrap"
                        v-text="project.title"
                    ></v-card-title>
                </div>
                <div v-if="thumbnail" class="play__button">
                    <v-icon color="primary" size="30">
                        fas fa-play
                    </v-icon>
                </div>
            </v-img>
            <display-video
                v-else-if="project.youtube_url"
                :url="project.youtube_url"
                :width="mediaWidth"
                :height="mediaHeight"
            ></display-video>

            <v-img
                v-if="project.library_file"
                class="white--text align-end"
                contain
                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                :height="mediaHeight"
            >
                <v-icon class="ps-icon" size="50">
                    fa-solid fa-file-pdf
                </v-icon>
                <v-card-title
                    class="card-title"
                    v-text="project.title"
                ></v-card-title>
            </v-img>
        </v-card-text>

        <v-card-actions class="card-actions">
            <h3>{{ project.user.name }}</h3>
            <v-spacer></v-spacer>

            <slot name="editProject"></slot>
            <slot name="deleteProject"></slot>
            <slot name="viewAction"></slot>

            <like-btn :project="project" :type="type"></like-btn>

            <book-mark-btn :project="project" :type="type"></book-mark-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import BookMarkBtn from "./BookMarkBtn.vue";
import LikeBtn from "./LikeBtn.vue";
import DisplayVideo from "../General/DisplayVideo.vue";
export default {
    name: "ProjectInfoCard",

    props: {
        project: {
            required: true,
            type: Object,
        },
        cardHeigth: {
            required: true,
            type: Number,
        },
        cardWidth: {
            required: true,
            type: String,
        },
        mediaHeight: {
            required: true,
            type: Number,
        },
        mediaWidth: {
            required: true,
            type: String,
        },
        type: {
            required: true,
            type: String,
        },
        clickable: {
            required: false,
            type: Boolean,
            default: true,
        },
        thumbnail: {
            required: false,
            type: Boolean,
            default: false,
        },
    },

    components: {
        DisplayVideo,
        LikeBtn,
        BookMarkBtn,
    },

    computed: {
        computedImageSource() {
            if (this.thumbnail) {
                return this.thumbnailURL();
            }

            return this.project.image_url;
        },
    },

    methods: {
        emitProject(value) {
            this.$emit("set-project", value);
        },
        redirectToInfoPage() {
            this.$emit("card-click");
        },

        thumbnailURL() {
            let videoId = this.stripVideoId(this.project.youtube_url);
            return `https://img.youtube.com/vi/${videoId}/0.jpg`;
        },

        stripVideoId(url) {
            var regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
            var match = url.match(regExp);
            if (match && match[2].length == 11) {
                return match[2];
            } else {
                // return "";
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.ps-relative {
    position: relative;
    padding: 8em 0;
}

.card-content {
    background-color: var(--v-primary-lighten4);
}

.card-content:hover {
    cursor: pointer;
}
.card-title {
    font-size: 1.2em;
    font-weight: 600;
    letter-spacing: 0.04;
}

.str-img {
    position: absolute;
    top: 0;
    z-index: 10;
    left: 10%;
    bottom: 49%;
}

.ps-icon {
    position: absolute;
    top: 36%;
    left: 43%;
}

.play__button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>
