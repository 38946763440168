<template>
    <iframe
        :width="width"
        :height="height"
        :src="result"
        frameborder="0"
        allowfullscreen
    ></iframe>
</template>

<script>
export default {
    name: "DisplayVideo",

    props: {
        url: {
            required: true,
            type: String,
        },
        width: {
            required: true,
            type: String,
        },
        height: {
            required: true,
            type: Number,
        },
    },

    computed: {
        result() {
            let url = this.loadURL();
            return url;
        },
    },

    methods: {
        loadURL() {
            const youtubeEmbedTemplate = "https://www.youtube.com/embed/";
            const url = this.url.split(
                /(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/
            );
            const YId =
                undefined !== url[2]
                    ? url[2].split(/[^0-9a-z_/\\-]/i)[0]
                    : url[0];
            if (YId === url[0]) {
                //
            } else {
                //
            }
            const topOfQueue = youtubeEmbedTemplate.concat(YId);
            return topOfQueue;
        },
    },
};
</script>
