<template>
    <div>
        <v-btn icon plain small color="text" @click="handleBookmark()">
            <v-icon size="18" :color="isBookmarked ? 'primary darken-1' : ''">
                fa-solid fa-star
            </v-icon>
        </v-btn>
        <v-dialog v-model="dialogOpen" persistent max-width="300">
            <v-card>
                <v-card-title>
                    {{ $t("messages.bookmarkBtn.dialogTitle") }}
                </v-card-title>
                <v-card-text>
                    {{ $t("messages.bookmarkBtn.dialog") }}
                </v-card-text>
                <v-card-actions class="justify-end">
                    <v-btn text @click="dialogOpen = false">
                        {{ $t("messages.bookmarkBtn.cancel") }}
                    </v-btn>
                    <v-btn
                        depressed
                        color="primary lighten2"
                        @click="registerRedirect()"
                    >
                        {{ $t("messages.bookmarkBtn.login") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { withAsync } from "@/helpers/withAsync.js";
import { bookmarkProject } from "@/api/projectsApi.js";
import { mapState, mapGetters } from "vuex";
export default {
    name: "BookMarkBtn",

    props: {
        project: {
            required: true,
            type: Object,
        },
        type: {
            required: true,
            type: String,
        },
    },

    data() {
        return {
            isBookmarked: false,
            dialogOpen: false,
        };
    },

    computed: {
        ...mapState({
            user_role: (state) => state.auth.user.role,
        }),
        ...mapGetters({
            isLoggedIn: "auth/isLoggedIn",
        }),
    },

    methods: {
        async handleBookmark() {
            if (!this.isLoggedIn) {
                this.dialogOpen = true;
                return;
            }
            let url = this.isBookmarked
                ? `${this.user_role}/${this.type}/unfavorite`
                : `${this.user_role}/${this.type}/favorite`;

            const payload = {
                [`${this.type}_id`]: this.project.id,
            };

            const { response, error } = await withAsync(
                bookmarkProject,
                payload,
                url
            );

            if (error) {
                return;
            }
            this.isBookmarked = response.data.data.isBookmarked;
        },
        registerRedirect() {
            this.$router.push({ name: "Register" });
        },
    },

    created() {
        this.isBookmarked = this.project.isBookmarked;
    },
};
</script>
